<template>
<div class="share animate__animated animate__fadeInDown">
    <div class="title">
        <router-link class="back" to="/" >
            <a-icon type="left" />
        </router-link>
        {{ $t('share.title') }}
    </div>
    <div class="box">
        <vue-qr :correctLevel="3" :autoColor="false" colorDark="#313a90" :text="shareUrl" :size="95" :margin="0" :logoMargin="3"></vue-qr>
    </div>

</div>
  
</template>
<script>
import VueQr from 'vue-qr';
import config from '../config'
export default {
  name: "Share",
  data() {
    return {
      shareUrl: config._BASE_URL+'register/'+this.$store.state.userinfo.address
    };
  },
  components:{
      VueQr
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData(){
        console.log(this.shareUrl)
    },
  },
};
</script>
<style scoped>
.share{
    padding: 15px;
    width: 100%;
}
.title{
    position:relative;
    color: #fff;
    font-size: 1rem;
}
.title .back{
    position:absolute;
    left: 0;
    color:#fff;
}
.box {
    border-radius: 4px;
    margin: 1rem auto;
    padding: 15px;
    position:relative;
    text-align: center;
}
.box::before{
    content: " ";
    position:absolute;
    left:0;top:0;bottom:0;width:100%;
    background: #fff;
    opacity: .3;
}
.box .item{
    padding:10px;
    position:relative
}
.nodeLevel{
    position:absolute;
    top:8px;right:0;
}
</style>
